<template>
  <b-card>
    <b-row>
      <b-col>
        <b-table bordered hover responsive :fields="fields">
          <!-- <template #cell(#)="data">
            {{ data.index + 1 }}
          </template> -->
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BTable,
  },
  data() {
    return {
      fields: [
        // "#",
        {
          key: "name",
          label: "User",
          sortable: true,
        },
        {
          key: "firewall",
          label: "Firewall",
          sortable: true,
        },
      ],
    };
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
    // this.appid = this.$route.params.id;
  },
  methods: {},
};
</script>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>